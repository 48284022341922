<template>
    <div>
        <GroupInvite
            ref="emailInvite"
            class="mb-4"
            :form="form"
            :orgId="organizationId" />
        <MassiveInvite
            ref="massiveInvite"
            :form="form"
            :orgId="organizationId" />
    </div>
</template>

<script>
import MassiveInvite from './MassiveInvite.vue'
import GroupInvite from './GroupInvite.vue'
export default {
    components: {
        MassiveInvite,
        GroupInvite
    },
    props: {
        organizationId: {
            type: String,
            defalut: null
        },
        form: {
            type: Object,
            required: true
        }
    }
}
</script>